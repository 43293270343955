import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API } from '@app-core/constants/api.constants';
import { DEFAULT_DATA_RETENTION_DAYS, DEFAULT_DURATION_LIST } from '@app-core/constants/constants';
import { ClientConfigResponse, Duration } from '@app-core/models/core.model';
import { Observable } from 'rxjs';
import { shareReplay } from 'rxjs/operators';

@Injectable({
  providedIn: 'root',
})
export class DurationRangeService {
  private durationList: Duration[] = DEFAULT_DURATION_LIST;

  private _dataRetentionDays: number = DEFAULT_DATA_RETENTION_DAYS;

  private _includePolylinePathInfo: boolean = false;

  public get dataRetentionDays() {
    return this._dataRetentionDays;
  }

  public get includePolylinePathInfo() {
    return this._includePolylinePathInfo;
  }

  public set dataRetentionDays(value: number) {
    this._dataRetentionDays = value;
    this.updateDurationList();
  }

  public set includePolylinePathInfo(value: boolean) {
    this._includePolylinePathInfo = value;
  }

  constructor(private http: HttpClient) {}

  public getDurationList(): Duration[] {
    return this.durationList;
  }

  public updateDurationList() {
    this.durationList = this.getCustomDurationList(this.durationList, this.dataRetentionDays);
  }

  public getCustomDurationList(customDurationList: Duration[], dataRetentionPeriod = this.dataRetentionDays): Duration[] {
    let verifiedList = customDurationList.filter((item: Duration) => {
      if (item.days === 'custom' || item.days === 'yesterday') {
        return true;
      } else if (typeof item.days === 'number') {
        return item.days <= dataRetentionPeriod;
      }
      return false;
    });
    return verifiedList;
  }

  public getClientConfig(): Observable<ClientConfigResponse> {
    return this.http.get<ClientConfigResponse>(API.CLIENT_CONFIG).pipe(shareReplay({ bufferSize: 1, refCount: true }));
  }
}
